<template>
  <div style="height: 100vh;">
    <el-container style="height: 100%;">
      <!-- 侧边栏aside -->
      <el-aside :width="sidewidth + 'px'" style="background-color: rgb(238, 241, 246);overflow: hidden; box-shadow: 2px 0 6px rgb(0 21 41 / 35%)">
        <Aside :isCollapse="isCollapse" :logoTestShow="logoTestShow"/>
      </el-aside>

      <el-container>
        <!-- 顶部tab -->
        <el-header>
          <Header :collapseBtnClass="collapseBtnClass" :collapse="collapse" :user="user"/>
        </el-header>
        <el-main>
          <!-- 表示当前页面的子路由会在 router-view 里面展示 -->
          <router-view @refreshUser="getUser"/>
        </el-main>
      </el-container>

    </el-container>
  </div>
</template>

<style>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
</style>

<script>

import Aside from '@/components/Aside.vue';
import Header from '@/components/Header.vue';

export default {
  data() {
      return {
        collapseBtnClass: 'el-icon-s-fold',
        isCollapse: false,
        logoTestShow: true,
        sidewidth: 200 ,
        user: {}
      }
    },
    components: {
      Aside,
      Header
    },
    created(){
      // 从后台获取最新的数据
      this.getUser()
    },
    methods:{
      // 点击收缩按钮，触发
      collapse(){
        this.isCollapse = !this.isCollapse
        if(this.isCollapse === true){ // 收缩
          this.sidewidth = 64
          this.collapseBtnClass = 'el-icon-s-unfold'
          this.logoTestShow = false
        }else{ // 展开
          this.sidewidth = 200
          this.collapseBtnClass = 'el-icon-s-fold'
          this.logoTestShow = true
        }
      },
      getUser(){
        let username = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).username : ""
        // 从后台获取user数据
        this.request.get("/user/username/" + username).then(res=>{
            // 重新赋值后台的最新User数据
            this.user = res.data
        })
      }
    }
  };
</script>
<template>
  <div style="display: flex;">
    <div style="flex:1;">
        <span :class="collapseBtnClass" style="cursor: pointer;font-size: 18px" @click="collapse"></span>

    <div separator="/" style="display: inline-block; margin-left: 10px; font-size: 15px;">
        <div @click="toHead" style="cursor: pointer;">首页</div>
    </div>

    </div>
    <el-dropdown style="width: 150px; cursor: pointer; text-align: right;">
        <div style="display: inline-block;">
            <img :src="user.avatarUrl" alt=""
                style="width: 29px; height: 29px; border-radius: 50%; position: relative; top: 10px; right: 5px; border:solid 1px black;">
            <span>{{ user.nickname }}</span><i class="el-icon-arrow-down" style="margin-left: 5px;"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
                <span style="text-decoration: none;color: black;" @click="toPerson">个人信息</span>
            </el-dropdown-item>
            <el-dropdown-item>
                <span style="text-decoration: none;color: black;" @click="logout">退出</span>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown> 
  </div>
</template>

<script>
export default {
    name: "Header",
    props:{
        collapseBtnClass: String,
        collapse: Function,
        user: Object
    },
    data(){
        return{
            
        }
    },
    methods: {
        toHead(){
            var currentPath = this.$route.path
            if(currentPath != '/home') {
                this.$router.push("/")
            }
            // console.log(currentPath)
        },
        toPerson(){
            this.$router.push("/person")
        },
        logout(){
            this.$router.push("/login")
            localStorage.removeItem("user")
            this.$message.success("退出成功")
        }
    }
}
</script>

<style>

</style>
<template>
  <div class="main">
    <el-menu 
        default-active="/"
        style="min-height: 100%; overflow: hidden; border-right-color: transparent"
        :collapse="isCollapse"
        background-color="rgb(48, 65, 86)" 
        :collapse-transition="false"
        text-color="#fff"
        active-text-color="#00ccff"
        router
    >
        <div style="height: 60px; line-height: 60px;text-align: center;">
            <img src="../assets/logo.png" alt="" style="width: 20px; position: relative; top: 5px;">
            <b style="color: white; margin-left:5px" v-if="logoTestShow">朴子啊啊啊啊啊</b>
        </div>

        <el-menu-item index="/">
            <i class="el-icon-house"></i>
            <span slot="title">主页</span>
        </el-menu-item>
        <el-submenu index="1">
            <template slot="title">
                <i class="el-icon-menu"></i>
                <span slot="title">系统管理</span>
            </template>
            <el-menu-item index="/user">
                <i class="el-icon-s-custom"></i>
                <span slot="title">用户管理</span>
            </el-menu-item>
            <el-menu-item index="/role">
                <i class="el-icon-success"></i>
                <span slot="title">角色管理</span>
            </el-menu-item>
            <el-menu-item index="/menu">
                <i class="el-icon-s-tools"></i>
                <span slot="title">菜单管理</span>
            </el-menu-item>
            <el-menu-item index="/file">
                <i class="el-icon-document"></i>
                <span slot="title">文件管理</span>
            </el-menu-item>
        </el-submenu>
    </el-menu>


  </div>
</template>


<script>
export default {
    name: "Aside",
    props: {
        isCollapse: Boolean,
        logoTestShow: Boolean
    }
}
</script>

<style>
.main{
    height: 100vh;
    background-color: rgb(48, 65, 86);
}
</style>